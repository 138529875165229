import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransField = createTrans({
  autoCancellation: (
    <Trans i18nKey="field.autoCancellation" defaults="Auto cancellation" />
  ),
  bookingAvailableBeforeDeparture: (
    <Trans
      i18nKey="field.bookingAvailableBeforeDeparture"
      defaults="Booking available before departure"
    />
  ),
  carrier: <Trans i18nKey="field.carrier" defaults="Carrier" />,
  city: <Trans i18nKey="field.city" defaults="City" />,
  country: <Trans i18nKey="field.country" defaults="Country" />,
  county: <Trans i18nKey="field.county" defaults="County" />,
  currency: <Trans i18nKey="field.currency" defaults="Currency" />,
  days: <Trans i18nKey="field.days" defaults="days" />,
  deadlinePercentOfTimeUntilDeparture: (
    <Trans
      i18nKey="field.deadlinePercentOfTimeUntilDeparture"
      defaults="Deadline: % of time until departure"
    />
  ),
  deadlineTimeAfterBooking: (
    <Trans
      i18nKey="field.deadlineTimeAfterBooking"
      defaults="Deadline: time after booking"
    />
  ),
  deadlineTimeAfterDeparture: (
    <Trans
      i18nKey="field.deadlineTimeAfterDeparture"
      defaults="Deadline: time after departure"
    />
  ),
  deadlineTimeBeforeBooking: (
    <Trans
      i18nKey="field.deadlineTimeBeforeBooking"
      defaults="Deadline: time before booking"
    />
  ),
  deadlineTimeBeforeDeparture: (
    <Trans
      i18nKey="field.deadlineTimeBeforeDeparture"
      defaults="Deadline: time before departure"
    />
  ),
  defaultPaymentDeadlineTime: (
    <Trans
      i18nKey="field.defaultPaymentDeadlineTime"
      defaults="Default payment deadline time"
    />
  ),
  destinationStop: (
    <Trans i18nKey="field.destinationStop" defaults="Destination stop" />
  ),
  feePercentage: <Trans i18nKey="field.feePercentage" defaults="Fee %" />,
  isMain: <Trans i18nKey="field.isMain" defaults="Is main" />,
  maxFee: <Trans i18nKey="field.maxFee" defaults="Maximum fee" />,
  minFee: <Trans i18nKey="field.minFee" defaults="Minimum fee" />,
  name: <Trans i18nKey="field.name" defaults="Name" />,
  notificationBeforePaymentDeadline: (
    <Trans
      i18nKey="field.notificationBeforePaymentDeadline"
      defaults="Notification(s) before payment deadline"
    />
  ),
  originStop: <Trans i18nKey="field.originStop" defaults="Origin stop" />,
  percent: <Trans i18nKey="field.percent" defaults="%" />,
  product: <Trans i18nKey="field.product" defaults="Product" />,
  productCategory: (
    <Trans i18nKey="field.productCategory" defaults="Product category" />
  ),
  promotionalDiscount: (
    <Trans
      i18nKey="field.promotionalDiscount"
      defaults="Promotional discount"
    />
  ),
  purchaseDateFrom: (
    <Trans i18nKey="field.purchaseDateFrom" defaults="Purchase date from" />
  ),
  purchaseDateTo: (
    <Trans i18nKey="field.purchaseDateTo" defaults="Purchase date to" />
  ),
  rollingTravelPeriodFrom: (
    <Trans
      i18nKey="field.rollingTravelPeriodFrom"
      defaults="Rolling travel period from"
    />
  ),
  rollingTravelPeriodTo: (
    <Trans
      i18nKey="field.rollingTravelPeriodTo"
      defaults="Rolling travel period to"
    />
  ),
  salesChannel: <Trans i18nKey="field.salesChannel" defaults="Sales channel" />,
  service: <Trans i18nKey="field.service" defaults="Service" />,
  shortFormDay: <Trans i18nKey="field.shortFormDay" defaults="d" />,
  shortFormHour: <Trans i18nKey="field.shortFormHour" defaults="h" />,
  shortFormMinute: <Trans i18nKey="field.shortFormMinute" defaults="m" />,
  showOnlyValidConfigurations: (
    <Trans
      i18nKey="field.showOnlyValidConfigurations"
      defaults="Show only valid configurations"
    />
  ),
  streetHouseNumber: (
    <Trans i18nKey="field.streetHouseNumber" defaults="Street, house number" />
  ),
  ticketPaymentDeadlineType: (
    <Trans
      i18nKey="field.ticketPaymentDeadlineType"
      defaults="Ticket payment deadline type"
    />
  ),
  timeToLive: <Trans i18nKey="field.timeToLive" defaults="Time to live" />,
  travelPeriodType: (
    <Trans
      i18nKey="field.travelPeriodType.label"
      defaults="Travel period type"
    />
  ),
  'travelPeriodType.FIXED': (
    <Trans
      i18nKey="field.travelPeriodType.option.FIXED"
      defaults="Fixed travel periods"
    />
  ),
  'travelPeriodType.ROLLING': (
    <Trans
      i18nKey="field.travelPeriodType.option.ROLLING"
      defaults="Rolling travel period"
    />
  ),
  travelDateFrom: (
    <Trans i18nKey="field.travelDateFrom" defaults="Travel date from" />
  ),
  travelDateTo: (
    <Trans i18nKey="field.travelDateTo" defaults="Travel date to" />
  ),
  type: <Trans i18nKey="field.type" defaults="Type" />,
  validFrom: <Trans i18nKey="field.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="field.validTo" defaults="Valid to" />,
  vatPercentage: <Trans i18nKey="field.vatPercentage" defaults="Tax %" />,
});
