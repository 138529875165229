import { FC } from 'react';
import { TransError } from '@fleet/shared/i18n/trans/error';
import { TransField } from 'i18n/trans/field';
import { Grid } from '@mui/material';
import { FormControl, ModalFieldArray, TextField } from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import _noop from 'lodash/noop';
import makeStyles from '@mui/styles/makeStyles';
import { renderToString } from 'react-dom/server';
import { TimeDuration } from '@fleet/shared/utils/timeDuration';

const useStyles = makeStyles(() => ({
  timeWrapper: { display: 'flex', alignItems: 'center', gap: '3px' },
  timeLabel: { paddingLeft: 5 },
  percentLabel: { paddingLeft: 5, paddingTop: 20 },
}));

interface NotificationTimesFieldProps {}

export const NotificationTimesField: FC<NotificationTimesFieldProps> = () => {
  const classes = useStyles();

  const validateRow = (rows?: Array<TimeDuration>) => {
    if (rows) {
      if (rows.some((row) => Object.keys(row).length === 0)) {
        return renderToString(<TransError i18nKey="required" />);
      }
    }
    return null;
  };

  return (
    <ModalFieldArray<TimeDuration>
      name="notificationTimes"
      label={<TransField i18nKey="notificationBeforePaymentDeadline" />}
      accessor={({ days, hours, minutes }) => {
        if (
          days !== undefined &&
          hours !== undefined &&
          minutes !== undefined
        ) {
          return `${days}d ${hours}h ${minutes}min`;
        }
        return '';
      }}
      validate={validateRow}
      render={({ name, removeButton }) => ({
        canRemove: false,
        field: (
          <Grid container spacing={2}>
            <Grid item sx={{ flex: 1 }}>
              <FormControl>
                <Grid
                  container
                  columns={4}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Grid item xs={1} className={classes.timeWrapper}>
                    <TextField name={`${name}.days`} type="number" />
                    <span className={classes.timeLabel}>
                      <TransField i18nKey="shortFormDay" />
                    </span>
                  </Grid>
                  <Grid item xs={1} className={classes.timeWrapper}>
                    <TextField name={`${name}.hours`} type="number" />
                    <span className={classes.timeLabel}>
                      <TransField i18nKey="shortFormHour" />
                    </span>
                  </Grid>
                  <Grid item xs={1} className={classes.timeWrapper}>
                    <TextField name={`${name}.minutes`} type="number" />
                    <span className={classes.timeLabel}>
                      <TransField i18nKey="shortFormMinute" />
                    </span>
                  </Grid>
                  <Grid item>{removeButton}</Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
        ),
      })}
      modalProps={{
        actionButton: {
          label: <TransButton i18nKey="confirm" />,
          icon: 'check',
          onClick: _noop,
        },
      }}
    />
  );
};
