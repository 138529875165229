import { Layout, Loadable } from '@fleet/shared';
import { bookingFeesLoadingSelector } from 'features/loading/loadingSelectors';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { BookingFeeDetails } from 'routes/BookingFee/BookingFeeDetails';
import { BookingFeeTable } from 'routes/BookingFee/BookingFeeTable';
import { useSelector } from 'store/utils';

interface BookingFeeProps {}

export const BookingFee: FC<BookingFeeProps> = () => {
  const loading = useSelector(bookingFeesLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/booking-fee/:action(create|edit)/:id?"
              component={BookingFeeDetails}
              exact
            />
          </Switch>
        }
      >
        <BookingFeeTable />
      </Layout>
    </Loadable>
  );
};
