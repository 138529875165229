import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';
import { Product } from 'dto/product';
import { Service } from 'dto/service';
import qs from 'qs';

export const fetchProducts = async (ownerId: number) =>
  (
    await api.get<Pagination<Product>>(
      `/products${qs.stringify(
        {
          ownerId,
          limit: 999999,
          excludeBundles: true,
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_PM,
      }
    )
  ).data.items;

export const fetchPromotionalDiscounts = async (ownerId: number) =>
  (
    await api.get<Pagination<Classifier>>(
      `/promotional-discounts${qs.stringify(
        {
          ownerId,
          limit: 999999,
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_DM,
      }
    )
  ).data.items;

export const fetchServices = async (carrierId: number) =>
  (
    await api.get<Pagination<Service>>(
      `/service-codes${qs.stringify(
        {
          carrierId,
        },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`,
      {
        baseURL: process.env.REACT_APP_API_LM,
      }
    )
  ).data.items;
