import { Period, StopsSelectValue } from '@fleet/shared';
import type { PaginationParams } from '@fleet/shared/dto/pagination';
import { Classifier } from '@fleet/shared/dto/classifier';

export enum TicketTextType {
  PRODUCT = 'TICKET_TEXT_TYPE.PRODUCT',
  DISCOUNT = 'TICKET_TEXT_TYPE.DISCOUNT',
  SERVICE = 'TICKET_TEXT_TYPE.SERVICE',
}

export interface TicketTextLocalization {
  id: string;
  name: string;
  localizedValue: string;
  culture: Classifier;
}

export interface TicketTextBase {
  name: string;
  carrierId: number;
}

export interface TicketText extends TicketTextBase {
  id: string;
  ticketTextType: { id: TicketTextType; name: string | null } | null;
  productIds: string[] | null;
  promotionalDiscountIds: string[] | null;
  serviceCodes: number[] | null;
  orderNumber: string;
  purchasePeriod: Period;
  fixedTravelPeriod: Period;
  rollingTravelPeriod: {
    from: number;
    to: number;
  };
  localizations: TicketTextLocalization[];
  originStop: Classifier<number> | null;
  destinationStop: Classifier<number> | null;
}

interface TicketTextStopMaps {
  originStopMap?: StopsSelectValue | null;
  destinationStopMap?: StopsSelectValue | null;
}

export interface TicketTextFilter
  extends TicketTextBase,
    TicketTextStopMaps,
    PaginationParams {
  productId: string | null;
  promotionalDiscountId: string | null;
  serviceCode: number | null;
  ticketTextTypeIds: TicketTextType[] | null;
  purchaseFromPeriod: Period;
  purchaseToPeriod: Period;
  travelFromPeriod: Period;
  travelToPeriod: Period;
}

type TicketTextStopWrapper<TValue> = Omit<
  TValue,
  'originStop' | 'destinationStop'
> & { originStopId: number | undefined; destinationStopId: number | undefined };

export interface TicketTextFilterRequest
  extends TicketTextStopWrapper<Partial<TicketTextFilter>> {}

export interface TicketTextValues
  extends Partial<
      Omit<
        TicketText,
        'ticketTextType' | 'localizations' | 'originStop' | 'destinationStop'
      >
    >,
    TicketTextStopMaps {
  travelPeriodType?: 'fixed' | 'rolling';
  ticketTextTypeId: TicketTextType;
}

export interface TicketTextValuesRequest
  extends TicketTextStopWrapper<Partial<TicketTextValues>> {}
