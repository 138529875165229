import { Classifier } from '@fleet/shared/dto/classifier';
import { BusinessEntityType } from '@fleet/shared';

export const ALLOWED_BUSINESS_ENTITY_ROLES = [
  BusinessEntityType.CARRIER,
  BusinessEntityType.RETAILER,
];

export interface ClassificationGroupItem {
  id: ClassificationKey;
  classifications: Array<Classifier>;
}

export enum ClassificationGroup {
  CULTURE = 'CULTURE',
  CURRENCY = 'CURRENCY',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  SALES_CHANNEL = 'SALES_CHANNEL',
  TICKET_PAYMENT_DEADLINE_TYPE = 'TICKET_PAYMENT_DEADLINE_TYPE',

  // !ClassificationGroups
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COUNTRY = 'COUNTRY',
}

export const STANDARD_CLASSIFICATION_GROUPS = [
  ClassificationGroup.CULTURE,
  ClassificationGroup.CURRENCY,
  ClassificationGroup.PRODUCT_CATEGORY,
  ClassificationGroup.SALES_CHANNEL,
  ClassificationGroup.TICKET_PAYMENT_DEADLINE_TYPE,
] as const;

export type ClassificationKey = Extract<
  ClassificationGroup,
  typeof STANDARD_CLASSIFICATION_GROUPS[keyof typeof STANDARD_CLASSIFICATION_GROUPS]
>;
