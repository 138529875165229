import {
  ConfirmDeleteButton,
  formatDate,
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
  useRowActive,
  useTable,
  useTableRowHighlight,
} from '@fleet/shared';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { Divider, Stack, Typography } from '@mui/material';
import { BookingFee } from 'dto/bookingFee';
import {
  deleteBookingFees,
  getBookingFees,
} from 'features/bookingFee/bookingFeeActions';
import {
  bookingFeesFilterSelector,
  bookingFeesSelector,
} from 'features/bookingFee/bookingFeeSelectors';
import { bookingFeesLoadingSelector } from 'features/loading/loadingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import { FC, useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { Row, usePagination, useRowSelect } from 'react-table';
import { BookingFeeSearchForm } from 'routes/BookingFee/BookingFeeSearchForm';
import { useDispatch, useSelector } from 'store/utils';
import { useHistory, useParams } from 'react-router-dom';

interface BookingFeeTableProps {}

export const BookingFeeTable: FC<BookingFeeTableProps> = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const bookingFees = useSelector(bookingFeesSelector);
  const filter = useSelector(bookingFeesFilterSelector);
  const loading = useSelector(bookingFeesLoadingSelector);
  const data = useMemo(() => bookingFees?.items ?? [], [bookingFees]);

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getBookingFees({
          ...filter,
          ...paginationParams,
        })
      ).unwrap(),
    [dispatch, filter]
  );

  const link = useCallback(
    (row: Row<BookingFee>) =>
      `/booking-fee/edit/${row.original.id}?ownerId=${row.original.ownerId}`,
    []
  );

  const getRowId = useCallback((row: BookingFee) => row.id, []);

  const columns = useMemo<TableColumns<BookingFee>>(
    () => [
      {
        id: 'carriers',
        accessor: ({ carriers }) =>
          carriers.length ? carriers.map(({ name }) => name).join(', ') : '-',
        Header: <TransTableHead i18nKey="carrier" />,
      },
      {
        id: 'salesChannel.name',
        accessor: ({ salesChannel }) => salesChannel.name,
        Header: <TransTableHead i18nKey="salesChannel" />,
      },
      {
        id: 'productCategory.name',
        accessor: ({ productCategory }) => productCategory.name,
        Header: <TransTableHead i18nKey="productCategory" />,
      },
      {
        id: 'currency.name',
        accessor: ({ currency }) => currency.name,
        Header: <TransTableHead i18nKey="currency" />,
      },
      {
        id: 'validityPeriod.from',
        accessor: ({ validityPeriod }) =>
          validityPeriod.from ? formatDate(validityPeriod.from) : '-',
        Header: <TransTableHead i18nKey="validFrom" />,
      },
      {
        id: 'validityPeriod.to',
        accessor: ({ validityPeriod }) =>
          validityPeriod.to ? formatDate(validityPeriod.to) : '-',
        Header: <TransTableHead i18nKey="validTo" />,
      },
    ],
    []
  );

  const table = useTable<BookingFee>(
    {
      data,
      columns,
      total: bookingFees?.totalCount,
      offset: bookingFees?.offset,
      limit: filter.limit,
      onPageChange: handlePageChange,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useIndeterminateRowSelectCheckbox
  );

  const { selectedFlatRows } = table;

  useTableRowHighlight(id, table);

  const handleRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBookingFees(selectedFlatRows.map(({ original }) => original))
    ).unwrap();
    alert.success(<TransAlert i18nKey="bookingFeeDeleted" />);
  }, [dispatch, selectedFlatRows, alert]);

  return (
    <>
      <BookingFeeSearchForm />
      <Divider />
      <SearchResult results={!!data.length} loading={loading}>
        <Table
          caption={
            <>
              <TableCaption>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      <TransSubtitle
                        i18nKey="selectedQty"
                        values={{
                          num: table.selectedFlatRows.length,
                        }}
                      />
                    </Typography>
                    <ConfirmDeleteButton
                      title={<TransModal i18nKey="bookingFeeDeletionTitle" />}
                      description={
                        <TransModal
                          i18nKey="bookingFeeDeletionDescription"
                          values={{ count: table.selectedFlatRows.length }}
                        />
                      }
                      disabled={!table.selectedFlatRows.length}
                      onConfirm={handleRowsRemove}
                      data-testid={'delete-booking-fees-button'}
                    />
                  </Stack>
                </Stack>
              </TableCaption>
            </>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
