import { FC } from 'react';
import { useSelector } from 'store/utils';
import { onHoldBookingsLoadingSelector } from 'features/loading/loadingSelectors';
import { Layout, Loadable } from '@fleet/shared';
import { Route, Switch } from 'react-router-dom';
import { OnHoldBookingConfigurationDetails } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationDetails';
import { OnHoldBookingConfigurationTable } from 'routes/OnHoldBookingConfigurations/OnHoldBookingConfigurationTable';

export const OnHoldBookingConfigurations: FC = () => {
  const loading = useSelector(onHoldBookingsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/on-hold-bookings/:action(create|edit)/:id?"
              component={OnHoldBookingConfigurationDetails}
              exact
            />
          </Switch>
        }
      >
        <OnHoldBookingConfigurationTable />
      </Layout>
    </Loadable>
  );
};
