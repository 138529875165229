import { Loadable, Layout } from '@fleet/shared';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'store/utils';
import { ticketTextsLoadingSelector } from 'features/loading/loadingSelectors';
import { TicketTextTable } from 'routes/TicketTexts/TicketTextTable';
import { TicketTextDetails } from 'routes/TicketTexts/TicketTextDetails';

interface TicketTextsProps {}

export const TicketTexts: FC<TicketTextsProps> = () => {
  const loading = useSelector(ticketTextsLoadingSelector);

  return (
    <Loadable loading={loading}>
      <Layout
        drawer={
          <Switch>
            <Route
              path="/ticket-texts/:action(create|edit)/:id?"
              component={TicketTextDetails}
              exact
            />
          </Switch>
        }
      >
        <TicketTextTable />
      </Layout>
    </Loadable>
  );
};
