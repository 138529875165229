import { createLoadingSelector } from 'store/utils';
import {
  getBusinessEntities,
  getClassifications,
} from 'features/classification/classificationActions';
import {
  deleteOnHoldBookingConfigurations,
  getOnHoldBookingConfiguration,
  getOnHoldBookingConfigurations,
  createOrUpdateOnHoldBookingConfiguration,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationActions';
import {
  copyTicketText,
  createTicketText,
  deleteTicketText,
  deleteTicketTextLocalizationsBulk,
  getTicketText,
  getTicketTexts,
  updateOrCreateTicketTextLocalization,
  updateTicketText,
} from 'features/ticketText/ticketTextActions';
import {
  createTTLConfiguration,
  getTTLConfiguration,
  getTTLConfigurations,
  updateTTLConfiguration,
} from 'features/timeToLive/timeToLiveActions';
import {
  createBookingFee,
  deleteBookingFees,
  getBookingFee,
  getBookingFees,
  updateBookingFee,
} from 'features/bookingFee/bookingFeeActions';

export const appLoadingSelector = createLoadingSelector(
  getClassifications,
  getBusinessEntities
);

export const onHoldBookingsLoadingSelector = createLoadingSelector(
  getOnHoldBookingConfigurations,
  deleteOnHoldBookingConfigurations,
  getOnHoldBookingConfiguration,
  createOrUpdateOnHoldBookingConfiguration
);

export const onHoldBookingLoadingSelector = createLoadingSelector(
  getOnHoldBookingConfiguration,
  createOrUpdateOnHoldBookingConfiguration
);

export const ticketTextsLoadingSelector = createLoadingSelector(
  getTicketTexts,
  deleteTicketText,
  getTicketText,
  createTicketText,
  updateTicketText,
  updateOrCreateTicketTextLocalization,
  deleteTicketTextLocalizationsBulk,
  copyTicketText
);

export const ticketTextLoadingSelector = createLoadingSelector(
  getTicketText,
  createTicketText,
  updateTicketText
);

export const ttlConfigurationsLoadingSelector = createLoadingSelector(
  getTTLConfigurations,
  getTTLConfiguration,
  createTTLConfiguration,
  updateTTLConfiguration
);

export const ttlConfigurationLoadingSelector = createLoadingSelector(
  getTTLConfiguration,
  createTTLConfiguration,
  updateTTLConfiguration
);

export const bookingFeesLoadingSelector = createLoadingSelector(
  getBookingFees,
  deleteBookingFees,
  getBookingFee,
  createBookingFee,
  updateBookingFee
);

export const bookingFeeLoadingSelector = createLoadingSelector(
  getBookingFee,
  createBookingFee,
  updateBookingFee
);
