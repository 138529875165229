import { Option } from '@fleet/shared/dto/option';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { useMemo } from 'react';
import { useSelector } from 'store/utils';

export const useCarrierInitValue = (carrierOptions: Array<Option<number>>) => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  return useMemo(
    () =>
      carrierOptions.find(({ value }) => value === currentBusinessEntityId)
        ?.value,
    [carrierOptions, currentBusinessEntityId]
  );
};
