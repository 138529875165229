import {
  Accordion,
  AccordionPanel,
  Button,
  CardHeader,
  ConditionField,
  ConfirmDeleteButton,
  DrawerForm,
  DrawerFormProps,
  formatPeriodDatesWithoutTime,
  FormControl,
  FormProvider,
  formSubmit,
  Icon,
  makeClassificationOptions,
  PeriodField,
  RadioGroupField,
  SelectField,
  StopsSelect,
  TextField,
  Tooltip,
  useBusinessEntitiesByType,
  useForm,
  useLoaded,
} from '@fleet/shared';
import {
  ButtonGroup,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TicketTextType, TicketTextValues } from 'dto/ticketTexts';
import { ticketTextLoadingSelector } from 'features/loading/loadingSelectors';
import {
  copyTicketText,
  createTicketText,
  deleteTicketText,
  getTicketText,
  getTicketTexts,
  setTicketText,
  updateTicketText,
} from 'features/ticketText/ticketTextActions';
import { currentTicketTextSelector } from 'features/ticketText/ticketTextSelectors';
import { useCarrierInitValue } from 'hooks/useCarrierInitValue';
import { transformStop, useTicketTextsForm } from 'hooks/useTicketTextsForm';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransModal } from 'i18n/trans/modal';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory, useParams } from 'react-router-dom';
import { TicketTextDetailsLocalization } from 'routes/TicketTexts/TicketTextDetailsLocalization';
import { useDispatch, useSelector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

interface TicketTextsDetailsFormProps {}

const TICKET_TEXTS_FIELDS_TO_RESET = [
  'productIds',
  'promotionalDiscountIds',
  'serviceCodes',
] as const;

export const TicketTextDetails: FC<TicketTextsDetailsFormProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { action, id } = useParams<{ action: string; id?: string }>();
  const isEditing = useMemo(
    () => action === 'edit' && Boolean(id),
    [action, id]
  );

  const ticketText = useSelector(currentTicketTextSelector);
  const businessEntities = useSelector(businessEntitiesSelector);
  const { CARRIER } = useBusinessEntitiesByType(businessEntities);
  const carrierOptions = makeClassificationOptions(CARRIER);
  const carrierIdFormInitValue = useCarrierInitValue(carrierOptions);
  const travelPeriodTypeOptions = useMemo(
    () => [
      {
        value: 'fixed',
        label: <TransField i18nKey="travelPeriodType.FIXED" />,
      },
      {
        value: 'rolling',
        label: <TransField i18nKey="travelPeriodType.ROLLING" />,
      },
    ],
    []
  );

  const loading = useSelector(ticketTextLoadingSelector);
  const loaded = useLoaded(loading);

  const handleGoBack = useCallback(() => {
    history.replace('/ticket-texts');
  }, [history]);

  const handleCloseEditForm: DrawerFormProps['onClose'] = useCallback(
    (_, reason) => {
      if (reason === 'close') {
        handleGoBack();
      }
    },
    [handleGoBack]
  );

  useEffect(() => {
    dispatch(setTicketText());

    if (isEditing && id) {
      dispatch(getTicketText(id)).unwrap().catch(handleGoBack);
    }

    return () => {
      dispatch(setTicketText());
    };
  }, [action, dispatch, handleGoBack, id, isEditing]);

  const initialValues = useMemo<TicketTextValues>(
    () => ({
      carrierId: carrierIdFormInitValue,
      ticketTextTypeId: TicketTextType.PRODUCT,
      productIds: [],
      promotionalDiscountIds: [],
      serviceCodes: [],
      originStopMap: new Map(),
      destinationStopMap: new Map(),
      ...(ticketText && {
        ...ticketText,
        travelPeriodType: ticketText.fixedTravelPeriod ? 'fixed' : 'rolling',
        ticketTextTypeId: ticketText.ticketTextType?.id,
        productIds: ticketText.productIds ?? [],
        promotionalDiscountIds: ticketText.promotionalDiscountIds ?? [],
        serviceCodes: ticketText.serviceCodes ?? [],
        ...(ticketText.originStop && {
          originStopMap: new Map([
            [ticketText.originStop.id, ticketText.originStop],
          ]),
        }),
        ...(ticketText.destinationStop && {
          destinationStopMap: new Map([
            [ticketText.destinationStop.id, ticketText.destinationStop],
          ]),
        }),
      }),
    }),
    [ticketText, carrierIdFormInitValue]
  );

  const alert = useAlert();
  const onSubmit = useCallback(
    async ({
      travelPeriodType,
      fixedTravelPeriod,
      rollingTravelPeriod,
      ...values
    }: TicketTextValues) =>
      formSubmit(async () => {
        const { id } = values;
        const data = await dispatch(
          (!id ? createTicketText : updateTicketText)({
            ...transformStop(values),
            purchasePeriod: formatPeriodDatesWithoutTime(values.purchasePeriod),
            ...(travelPeriodType === 'fixed'
              ? {
                  fixedTravelPeriod:
                    formatPeriodDatesWithoutTime(fixedTravelPeriod),
                }
              : { rollingTravelPeriod }),
          })
        ).unwrap();

        if (!id) {
          alert.success(<TransAlert i18nKey="ticketTextCreated" />);
          history.replace(`/ticket-texts/edit/${data.id}`);
        } else {
          alert.success(<TransAlert i18nKey="ticketTextUpdated" />);
        }

        dispatch(setTicketText(data));
        dispatch(getTicketTexts());
      }),
    [alert, dispatch, history]
  );

  const { form, dirty, submitting, handleSubmit, values } = useForm({
    initialValues,
    onSubmit,
    subscription: { dirty: true, submitting: true, values: true },
  });

  const { carrierId, ticketTextTypeId } = values;

  const isServiceType = useMemo(
    () => ticketTextTypeId === TicketTextType.SERVICE,
    [ticketTextTypeId]
  );

  const isProductType = useMemo(
    () => ticketTextTypeId === TicketTextType.PRODUCT,
    [ticketTextTypeId]
  );

  useEffect(() => {
    form.batch(() =>
      TICKET_TEXTS_FIELDS_TO_RESET.forEach((field) => {
        const fieldState = form.getFieldState(field);
        if (fieldState) {
          form.resetFieldState(field);
        }
      })
    );
  }, [form, ticketTextTypeId]);

  const handleCarrierChange = useCallback(
    () =>
      form.batch(() =>
        TICKET_TEXTS_FIELDS_TO_RESET.forEach((field) => {
          const fieldState = form.getFieldState(field);
          if (fieldState) {
            form.change(field);
            form.resetFieldState(field);
          }
        })
      ),
    [form]
  );

  const {
    productOptions,
    promotionalDiscountOptions,
    serviceOptions,
    typeOptions,
  } = useTicketTextsForm(carrierId);

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const handleCopy = useCallback(async () => {
    if (!id) {
      return;
    }

    const newId = await dispatch(copyTicketText(id)).unwrap();
    alert.success(<TransAlert i18nKey="ticketTextCopied" />);

    await dispatch(getTicketTexts());
    history.push(`/ticket-texts/edit/${newId}`);
  }, [alert, dispatch, history, id]);

  const handleDelete = useCallback(async () => {
    await dispatch(deleteTicketText(id!)).unwrap();
    dispatch(setTicketText());
    alert.success(<TransAlert i18nKey="ticketTextDeleted" />);
    history.replace('/ticket-texts/');
  }, [alert, dispatch, history, id]);

  return (
    <DrawerForm open onClose={handleCloseEditForm}>
      <>
        <FormProvider form={form}>
          <CardHeader
            isLight
            title={
              <Typography variant="subtitle">
                {isEditing ? (
                  ticketText?.name
                ) : loading ? (
                  <>&nbsp;</>
                ) : (
                  <TransSubtitle i18nKey="newTicketText" />
                )}
              </Typography>
            }
            action={
              <ButtonGroup>
                {isEditing && (
                  <>
                    <Button
                      variant="text"
                      startIcon={<Icon name="clone" />}
                      onClick={handleCopy}
                    >
                      <TransButton i18nKey="copy" />
                    </Button>
                    <ConfirmDeleteButton
                      title={<TransModal i18nKey="ticketTextDeletionTitle" />}
                      description={
                        <TransModal i18nKey="ticketTextDeletionDescription" />
                      }
                      onConfirm={handleDelete}
                      data-testid="delete-ticket-text-button"
                    />
                  </>
                )}
                <IconButton aria-label="close" onClick={handleGoBack}>
                  <Tooltip
                    content={<TransButton i18nKey="close" />}
                    delay={500}
                  >
                    <Icon name="close" size={24} />
                  </Tooltip>
                </IconButton>
              </ButtonGroup>
            }
          />
          <CardContent component="form" onSubmit={handleSubmit}>
            <Grid container columns={4} spacing={2}>
              <Grid item xs={1}>
                <TextField
                  name="name"
                  label={<TransField i18nKey="name" />}
                  required
                />
              </Grid>
              <Grid item xs={1}>
                <SelectField
                  name="carrierId"
                  label={<TransField i18nKey="carrier" />}
                  onChange={handleCarrierChange}
                  options={carrierOptions}
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <RadioGroupField
                  name="ticketTextTypeId"
                  label={<TransField i18nKey="type" />}
                  options={typeOptions}
                  inline
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <Divider sx={{ mx: -3 }} />
              </Grid>
              {!isServiceType && (
                <Grid item xs={2}>
                  <SelectField
                    name="productIds"
                    label={<TransField i18nKey="product" />}
                    options={productOptions}
                    multiple
                    required={ticketTextTypeId === TicketTextType.PRODUCT}
                  />
                </Grid>
              )}
              {!isServiceType && !isProductType && (
                <Grid item xs={2}>
                  <SelectField
                    name="promotionalDiscountIds"
                    label={<TransField i18nKey="promotionalDiscount" />}
                    options={promotionalDiscountOptions}
                    multiple
                    required={ticketTextTypeId === TicketTextType.DISCOUNT}
                  />
                </Grid>
              )}
              <Grid item xs={2}>
                <SelectField
                  name="serviceCodes"
                  label={<TransField i18nKey="service" />}
                  options={serviceOptions}
                  multiple
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="originStopMap"
                  label={<TransField i18nKey="originStop" />}
                  allowSearch
                />
              </Grid>
              <Grid item xs={1}>
                <StopsSelect
                  name="destinationStopMap"
                  label={<TransField i18nKey="destinationStop" />}
                  allowSearch
                />
              </Grid>

              <PeriodField
                from={{
                  name: 'purchasePeriod.from',
                  label: <TransField i18nKey="purchaseDateFrom" />,
                  required: true,
                }}
                to={{
                  name: 'purchasePeriod.to',
                  label: <TransField i18nKey="purchaseDateTo" />,
                }}
              />
              <Grid item xs={4}>
                <RadioGroupField
                  name="travelPeriodType"
                  label={<TransField i18nKey="travelPeriodType" />}
                  options={travelPeriodTypeOptions}
                  inline
                  required
                />
              </Grid>
              <ConditionField when="travelPeriodType" is="fixed">
                <PeriodField
                  from={{
                    name: 'fixedTravelPeriod.from',
                    label: <TransField i18nKey="travelDateFrom" />,
                    required: true,
                  }}
                  to={{
                    name: 'fixedTravelPeriod.to',
                    label: <TransField i18nKey="travelDateTo" />,
                  }}
                />
              </ConditionField>
              <ConditionField when="travelPeriodType" is="rolling">
                <Grid item xs={1}>
                  <TextField
                    name="rollingTravelPeriod.from"
                    label={<TransField i18nKey="rollingTravelPeriodFrom" />}
                    endAdornment={<TransField i18nKey="days" />}
                    required
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="rollingTravelPeriod.to"
                    label={<TransField i18nKey="rollingTravelPeriodTo" />}
                    endAdornment={<TransField i18nKey="days" />}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </ConditionField>
              <Grid item xs="auto" sx={{ ml: 'auto' }}>
                <Stack direction="row" flexWrap="nowrap">
                  <FormControl label="&nbsp;">
                    <Button
                      variant="text"
                      sx={{ whiteSpace: 'nowrap' }}
                      {...(!isEditing && { onClick: handleGoBack })}
                      {...(isEditing && {
                        onClick: handleReset,
                        disabled: !dirty,
                      })}
                    >
                      <TransButton
                        i18nKey={isEditing ? 'resetChanges' : 'cancel'}
                      />
                    </Button>
                  </FormControl>
                  <FormControl label="&nbsp;">
                    <Button
                      variant="contained"
                      icon={isEditing ? 'check' : 'plus'}
                      type="submit"
                      disabled={!dirty || submitting}
                    >
                      <TransButton i18nKey={isEditing ? 'save' : 'create'} />
                    </Button>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </FormProvider>
        {loaded && isEditing && ticketText && (
          <Accordion>
            <AccordionPanel
              id="localization"
              summary={
                <Stack
                  direction="row"
                  alignItems="baseline"
                  sx={{ gap: '5px' }}
                >
                  <Typography variant="subtitle">
                    <TransSubtitle i18nKey="localizations" />
                  </Typography>
                  <Typography variant="body2">
                    {`(${ticketText.localizations.length})`}
                  </Typography>
                </Stack>
              }
            >
              <TicketTextDetailsLocalization />
            </AccordionPanel>
          </Accordion>
        )}
      </>
    </DrawerForm>
  );
};
