import { api } from '@fleet/shared';
import { Pagination } from '@fleet/shared/dto/pagination';
import { createAction } from '@reduxjs/toolkit';
import { BookingFee, BookingFeeFilter, BookingFeeValues } from 'dto/bookingFee';
import { createAsyncThunk } from 'store/utils';
import qs from 'qs';

export const setBookingFeesFilter = createAction<Partial<BookingFeeFilter>>(
  'bookingFee/setBookingFeesFilter'
);

export const clearBookingFees = createAction('bookingFee/clearBookingFees');

export const getBookingFees = createAsyncThunk<
  Pagination<BookingFee>,
  Partial<BookingFeeFilter> | undefined
>('bookingFee/getBookingFees', async (values, { getState, dispatch }) => {
  if (values) {
    dispatch(setBookingFeesFilter(values));
  }

  const {
    bookingFee: { filter },
  } = getState();

  const { ownerId, ...rest } = filter;

  return (
    await api.get<Pagination<BookingFee>>(
      `/owners/${ownerId}/configuration/booking-fee${qs.stringify(rest, {
        addQueryPrefix: true,
        skipNulls: true,
        allowDots: true,
      })}`
    )
  ).data;
});

export const setBookingFee = createAction<BookingFee | undefined>(
  'bookingFee/setBookingFee'
);

export const getBookingFee = createAsyncThunk<
  BookingFee,
  { id: string; ownerId: string }
>('bookingFee/getBookingFee', async ({ id, ownerId }, { dispatch }) => {
  const { data } = await api.get(
    `/owners/${ownerId}/configuration/booking-fee/${id}`
  );
  dispatch(setBookingFee(data));

  return data;
});

export const createBookingFee = createAsyncThunk<
  BookingFee,
  Omit<BookingFeeValues, 'id'>
>('bookingFee/createBookingFee', async ({ ownerId, ...payload }) => {
  const { data } = await api.post<BookingFee>(
    `/owners/${ownerId}/configuration/booking-fee`,
    payload
  );

  return data;
});

export const updateBookingFee = createAsyncThunk<BookingFee, BookingFeeValues>(
  'bookingFee/updateBookingFee',
  async ({ id, ownerId, ...payload }) => {
    const { data } = await api.put<BookingFee>(
      `/owners/${ownerId}/configuration/booking-fee/${id}`,
      payload
    );

    return data;
  }
);

export const deleteBookingFees = createAsyncThunk<void, Array<BookingFee>>(
  'bookingFee/deleteBookingFees',
  async (bookingFees, { dispatch }) => {
    await Promise.all(
      bookingFees.map(({ id, ownerId }) =>
        api.delete(`/owners/${ownerId}/configuration/booking-fee/${id}`)
      )
    );
    await dispatch(getBookingFees());
  }
);
