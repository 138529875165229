import { FC, useCallback, useMemo } from 'react';
import { TransTitle } from 'i18n/trans/title';
import {
  AddButton,
  Button,
  formatPeriodDatesWithoutTime,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  RadioGroupField,
  SearchForm,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Link } from 'react-router-dom';
import { TransField } from 'i18n/trans/field';
import { Grid, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { useDispatch, useSelector } from 'store/utils';
import {
  getOnHoldBookingConfigurations,
  setOnHoldBookingConfigurationFilter,
} from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationActions';
import { onHoldBookingConfigurationsFilterSelector } from 'features/onHoldBookingConfiguration/onHoldBookingConfigurationSelectors';
import { OnHoldBookingConfigurationSearchFormValues } from 'dto/onHoldBookingConfiguration';
import { pick } from 'lodash';

export const OnHoldBookingConfigurationSearchForm: FC = () => {
  const dispatch = useDispatch();
  const ticketPaymentDeadlineTypeOptions = useClassificationOptions(
    ClassificationGroup.TICKET_PAYMENT_DEADLINE_TYPE
  );
  const filter = useSelector(onHoldBookingConfigurationsFilterSelector);

  const onSubmit = useCallback(
    async (values: OnHoldBookingConfigurationSearchFormValues) =>
      formSubmit(async () => {
        const { fromValidPeriod, toValidPeriod, ...rest } = values;
        (document.activeElement as HTMLInputElement)?.blur?.();
        await dispatch(
          getOnHoldBookingConfigurations({
            ...rest,
            fromValidPeriod: formatPeriodDatesWithoutTime(fromValidPeriod),
            toValidPeriod: formatPeriodDatesWithoutTime(toValidPeriod),
            offset: 0,
          })
        );
      }),
    [dispatch]
  );

  const initialValues = useMemo(() => filter, [filter]);

  const { form, handleSubmit } = useForm({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(
      setOnHoldBookingConfigurationFilter(pick(filter, ['limit', 'offset']))
    );
  }, [dispatch, filter, form]);

  return (
    <SearchForm
      title={<TransTitle i18nKey="onHoldBookingConfigurations" />}
      action={
        <AddButton component={Link} to="/on-hold-bookings/create">
          <TransButton i18nKey="add" />
        </AddButton>
      }
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container columns={5} spacing={2}>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <RadioGroupField
                label={<TransField i18nKey="isMain" />}
                name="isMain"
                options="BOOL"
                inline
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                name="ticketPaymentDeadlineTypeId"
                label={<TransField i18nKey="ticketPaymentDeadlineType" />}
                showEmptyOption
                options={ticketPaymentDeadlineTypeOptions}
              />
            </Grid>
            <PeriodField
              from={{
                name: 'fromValidPeriod.from',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'fromValidPeriod.to',
                label: <TransField i18nKey="validFrom" />,
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'toValidPeriod.from',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
              to={{
                name: 'toValidPeriod.to',
                label: <TransField i18nKey="validTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFilters" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
