import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  bookingFeeDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingFeeDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected booking fee? It cannot be undone];(2-inf)[Are you sure you want to delete selected booking fees? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  bookingFeeDeletionTitle: (
    <Trans
      i18nKey="modal.title.bookingFeeDeletionTitle"
      defaults="Delete booking fee"
    />
  ),
  bookingTtlDeletionDescription: (
    <Trans
      i18nKey="modal.title.bookingTtlDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected booking time to live? It cannot be undone];(2-inf)[Are you sure you want to delete selected booking times to live? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  bookingTtlDeletionTitle: (
    <Trans
      i18nKey="modal.title.bookingTtlDeletionTitle"
      defaults="Delete booking time to live"
    />
  ),
  localizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.localizationDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected localization? It cannot be undone];(2-inf)[Are you sure you want to delete selected localizations? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  localizationDeletionTitle: (
    <Trans
      i18nKey="modal.title.localizationDeletionTitle"
      defaults="Delete localization"
    />
  ),
  onHoldBookingDeletionDescription: (
    <Trans
      i18nKey="modal.title.onHoldBookingDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected on-hold booking configuration? It cannot be undone];(2-inf)[Are you sure you want to delete selected on-hold booking configurations? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  onHoldBookingDeletionTitle: (
    <Trans
      i18nKey="modal.title.onHoldBookingDeletionTitle"
      defaults="Delete on-hold booking configuration"
    />
  ),
  placeholder: <Trans i18nKey="modal.placeholder" defaults="Placeholder" />,
  ticketTextDeletionDescription: (
    <Trans
      i18nKey="modal.title.ticketTextDeletionDescription"
      defaults="(0-1)[Are you sure you want to delete selected ticket text? It cannot be undone];(2-inf)[Are you sure you want to delete selected ticket texts? It cannot be undone]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  ticketTextDeletionTitle: (
    <Trans
      i18nKey="modal.title.ticketTextDeletionTitle"
      defaults="Delete on-hold booking configuration"
    />
  ),
});
